import { FormHelperText, OutlinedInput } from "@mui/material";
import React from "react";

const errorTextStyle = {
  color: "#d33d50",
};

const MuiOutlinedInput = ({ children, helperText, ...rest }) => {
  console.log(rest, helperText);
  return (
    <React.Fragment>
      <OutlinedInput {...rest} />
      {helperText && (
        <FormHelperText sx={errorTextStyle}>{helperText}</FormHelperText>
      )}
    </React.Fragment>
  );
};

export default MuiOutlinedInput;
