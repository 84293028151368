import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";
import { TiDelete } from "react-icons/ti";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  backgroundColor: "#FFFFFF",
  outline: "none",
  borderRadius: "10px",
  p: "15px",
};

const FeedbackPopup = ({ open, handleClose, handleFeedback, handleSkip }) => {
  const [approved, setApproved] = useState(false);
  const [disapproved, setDisapproved] = useState(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div style={{ textAlign: "right", marginBottom: "30px" }}>
          <TiDelete
            size={35}
            color="#8C8C8C"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fonFamily: "Segoe UI",
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "21px",
              textAlign: "center",
            }}
          >
            Submit your approval
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            {approved ? (
              <HiThumbUp size={25} color="#03C972" />
            ) : (
              <HiOutlineThumbUp
                color="#DCDDDD"
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleFeedback(true, false);
                  setApproved(true);
                }}
              />
            )}

            {disapproved ? (
              <HiThumbDown size={25} color="#FF483E" />
            ) : (
              <HiOutlineThumbDown
                color="#DCDDDD"
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleFeedback(false, true);
                  setDisapproved(true);
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            fonFamily: "Segoe UI",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "21px",
            textAlign: "center",
            textDecoration: "underline",
            marginBottom: "30px",
            cursor: "pointer",
          }}
          onClick={handleSkip}
        >
          Skip for now
        </div>
      </Box>
    </Modal>
  );
};

export default FeedbackPopup;
