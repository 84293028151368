import React from "react";
import ApplicantInterviewDetails from "./ApplicantInterviewDetails";
import { headingStyle } from "./BasicInformation";
import DownLoadInterviewQuestionAndAnswers from "./DownLoadInterviewQuestionAndAnswers";
import QuestionAccordion from "./QuestionAccordion";
import WaitForInterview from "../uiComponents/WaitForInterview";

const InterviewInformation = ({ data }) => {
  console.log("data", data);
  if (data?.detail === "Interview not started yet") {
    return <WaitForInterview text={data?.detail} />;
  }

  return (
    <div>
      <ApplicantInterviewDetails data={data} />
      <div
        style={{
          ...headingStyle,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Questions review</span>
        <DownLoadInterviewQuestionAndAnswers data={data} />
      </div>
      {data?.data?.map((item, ind) => {
        return <QuestionAccordion key={item?.key} data={item} ind={ind} />;
      })}
    </div>
  );
};

export default InterviewInformation;
