// MyContext.js
import React, { createContext, useState } from "react";
import { proctoringTest } from "../services/user";

// Create a context with a default value
const UserContext = createContext();

// Create a provider component
const MyProvider = ({ children }) => {
  const [userStates, setUserStates] = useState({});
  const [switchTab,setSwitchTab]=useState([])

  const getProctorData = async (userId, video_url) => {
    setUserStates((prevState) => ({
      ...prevState,
      [userId]: { ...prevState[userId], loading: true }
    }));

    try {
      const resp = await proctoringTest({ video_url: video_url });
      setUserStates((prevState) => ({
        ...prevState,
        [userId]: { loading: false, proctoringData: resp }
      }));
    } catch (error) {
      setUserStates((prevState) => ({
        ...prevState,
        [userId]: { loading: false, proctoringData: {}, error: true }
      }));
    }

    console.log("handle proctor");
  };

  return (
    <UserContext.Provider value={{ getProctorData,userStates,setSwitchTab,switchTab }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, MyProvider };
