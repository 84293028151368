import React from "react";
import { IoCalendarClearOutline } from "react-icons/io5";
import { GoClock } from "react-icons/go";
import { BsBarChart } from "react-icons/bs";
import dayjs from "dayjs";

const interviewDetailsContainerStyle = {
  padding: "15px",
  backgroundColor: "#F5F5F5",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
};

const userDetailsStyle = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

const textStyle = {
  fontFamily: "Segoe UI",
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "19px",
  textAlign: "left",
  color: "#272727",
};

const dateAndTimeStyle = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
  fontFamily: "Segoe UI",
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "19px",
  textAlign: "left",
  color: "#272727",
};

const totalScoreStyle = {
  fontFamily: "Segoe UI",
  fontSize: "35px",
  fontWeight: 700,
  lineHeight: "46px",
  textAlign: "left",
  color: "#043A87",
};

const text2Style = {
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "17px",
  textAlign: "left",
  color: "#6F747E",
};

const ApplicantInterviewDetails = ({ data }) => {
  
  const date = dayjs(data?.date_time_interview);
  const formattedDate = date.format("D MMM YYYY"); // Format for the date
  const formattedTime = date.format("h:mm A");

  return (
    <div style={interviewDetailsContainerStyle}>
      <div style={userDetailsStyle}>
        <div>
          <img
            src={data?.photo_url ?? ""}
            alt="profile img"
            style={{ width: "60px", height: "60px", borderRadius: "6px" }}
          />
        </div>
        <div>
          <div style={{ ...textStyle, marginBottom: "10px" }}>{`${
            data.firstname ?? "--"
          } ${data.lastname ?? ""}`}</div>
          <div style={{ ...textStyle, fontWeight: 400 }}>
            {data?.email ?? "--"}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "40px",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "27px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={dateAndTimeStyle}>
            <IoCalendarClearOutline size={20} color="#6F747E" /> {formattedDate}
          </div>
          <div style={dateAndTimeStyle}>
            <GoClock size={20} color="#6F747E" /> {formattedTime}
          </div>
          <div style={dateAndTimeStyle}>
            <BsBarChart size={20} color="#6F747E" /> Total Score{" "}
            <span style={totalScoreStyle}>
              {data?.totalscorebycandidate}/{data?.totalscore_exam}
            </span>
          </div>
        </div>
        <div style={text2Style}>
          <div style={{ marginBottom: "10px" }}>
            From <span style={{ color: "#272727" }}>{data?.totalquestionsasked}</span> Question
          </div>
          <div>
            Skipped Questions <span style={{ color: "#272727" }}>{data?.skippedquestions}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantInterviewDetails;
