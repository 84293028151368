import React, { useContext, useEffect, useState } from "react";
import { ArrowDropDownOutlined } from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {
  AppBar,
  Button,
  Box,
  Typography,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";

import userProfile1 from "../assets/Guest-user.png";
import { UserContext } from "../context/UserContext";

const TopNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setSwitchTab } = useContext(UserContext);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const userName = localStorage.getItem("adminName") ?? "";
  const userProfile = localStorage.getItem("adminProfile") ?? userProfile1;
  const handleLogOut = () => {
    localStorage.clear();
    setSwitchTab([]);
    navigate("/login");
  };
  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
        backgroundColor: "white",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <Header title={pathname.substring(1)} />
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          {/* <IconButton>
            <img src={BellIcon} alt="notification" />
          </IconButton> */}

          <FlexBetween>
            <div
              className="border"
              style={{
                border: "1px solid var(--text-30, rgba(0, 0, 0, 0.30)",
                borderRadius: "100px",
              }}
            >
              <Button
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "0.8rem",
                }}
              >
                <Box
                  component="img"
                  alt="profile"
                  src={userProfile}
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                {/* <img
                  src={userProfile}
                  alt="profile"
                  style={{
                    height: "28px",
                    width: "70px",
                    borderRadius: "40px",
                  }}
                /> */}
                <Box textAlign="left">
                  <Typography
                    fontWeight="600"
                    fontSize="20px"
                    sx={{ color: "black", paddingTop: "5px" }}
                  >
                    {userName}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined
                  sx={{ color: "black", fontSize: "25px" }}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
              </Menu>
            </div>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;
