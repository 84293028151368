import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./SideBar";
import TopNavbar from "./TopNavbar";

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  return (
    <Box
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
      sx={{
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
    >
      <Sidebar isNonMobile={isNonMobile} drawerWidth="200px" />
      <Box flexGrow={1}>
        <TopNavbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
