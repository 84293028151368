import React, { useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { toast } from "react-toastify";

const Pagination = ({
  currentDataLength,
  totalPages,
  currentPage,
  handlePage,
}) => {
  const [pageNumber, setPageNumber] = useState("");

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      if (parseInt(pageNumber) <= totalPages) {
        handlePage(parseInt(pageNumber));
        setPageNumber("");
      } else {
        toast.error(`page ${pageNumber} not found`);
      }
    }
  };



  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFFFFF",
        padding: "10px 30px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          width:"50px",
          padding:"20px",
        }}
      >
        {/* <select
          style={{
            display: "block",
            width: "150px",
            backgroundColor: "#EEEEEE",
            padding: "6px 8px",
            border: "none",
            cursor: "pointer",
            borderRadius: "4px",
          }}
          value={pageSize}
          onChange={handleSize}
        >
          {[5, 10, 15, 20].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select> */}
        {/* <div
          style={{
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18px",
            letterSpacing: "0.02em",
            textAlign: "left",
          }}
        >
          Total Records: <span>10</span>
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <button
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            padding: "6px",
            border: "none",
            display: "flex",
            alignItems: "center",
          }}
          disabled={currentPage <= 1}
          onClick={() => {
            const newPage=parseInt(currentPage)-1
            handlePage(newPage?.toString())
          }}
        >
          <MdOutlineKeyboardArrowLeft size={18} className="w-[18px]" />
        </button>
        <div
          style={{
            fontFamily: "Segoe UI",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "13px",
            textAlign: "center",
            color: "#000000",
          }}
        >
          Result {currentPage } of {totalPages}
        </div>
        <button
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            padding: "6px",
            border: "none",
            display: "flex",
            alignItems: "center",
          }}
          disabled={currentPage >= totalPages}

          onClick={() => {
            const newPage=parseInt(currentPage)+1
            handlePage(newPage?.toString())
          }}
        >
          <MdOutlineKeyboardArrowRight size={18} className="w-[18px]" />
        </button>
      </div>
      <div>
        <span
          style={{
            fontFamily: "Segoe UI",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "13px",
            textAlign: "left",
          }}
        >
          Go to page:{" "}
        </span>
        <input
          type="number"
          onWheel={(e) => e?.currentTarget?.blur()}
          style={{
            width: "80px",
            borderRadius: "4px",
            backgroundColor: "#EEEEEE",
            padding: "6px 8px",
            border: "none",
          }}
          placeholder="EG:14"
          value={pageNumber}
          onChange={(e) => setPageNumber(e?.target?.value)}
          onKeyUp={(e) => handleSearch(e)}
        />
      </div>
    </div>
  );
};

export default Pagination;
