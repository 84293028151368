import React from "react";
import LabelValuePair from "./LabelValuePair";
import { Grid } from "@mui/material";
import VideoAnswer from "./VideoAnswer";
import dayjs from "dayjs";

export const headingStyle = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "21px",
  textAlign: "left",
  paddingBottom: "5px",
  margin: "20px 0px",
  borderBottom: "1px solid #D9D9D9",
};

const videoContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  gap: "40px",
  flexWrap: "wrap",
};

const BasicInformation = ({ data }) => {
  const dob=data?.dob ? dayjs(data?.dob).format('DD-MMM-YYYY') : '--'
  return (
    <div>
      <div style={headingStyle}>Personal Details</div>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair
              label="Name"
              value={`${data?.firstname ?? "--"} ${data?.lastname ?? ''}`}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="Mobile Number" value={data?.phone ?? "--"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="Date of Birth" value={dob} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="Gender" value={data?.gender ?? "--"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="Email" value={data?.email ?? "--"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair
              label="Profile photo"
              value={data.photo_url}
              link={!!data.photo_url}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <LabelValuePair
              label="Linkedin URL"
              value={data?.linkedin_url}
              link={!!data?.linkedin_url}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="City" value={data?.user_city ?? "--"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="State" value={data?.user_state ?? "--"} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LabelValuePair label="Country" value={data?.user_country ?? "--"} />
          </Grid>
        </Grid>
      </div>
      <div style={headingStyle}>Academic Details</div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <LabelValuePair
            label="Curriculum Vitae (CV)"
            value={data?.cv_url}
            link={!!data?.cv_url}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <LabelValuePair
            label="High School Marksheet"
            value={data?.highschool_url}
            link={!!data?.highschool_url}
          />
        </Grid>
      </Grid>
      <div style={headingStyle}>Application Questions and Video Answers</div>
      <div style={videoContainerStyle}>
        <VideoAnswer
          value={data?.startup_overview}
          text={
            `Upload a 2 minute video that explains "Why am I a potential candidate?"`
          }
          index={"1"}
          link={!!data?.startup_overview}
          transcription={data?.startup_overview_transcription}
        />
        <VideoAnswer
          value={data?.startup_inspiration}
          text={
            "Upload a 5 minute video talking about a problem that you are passionate about and the steps that you would take to solve it."
          }
          index={"2"}
          link={!!data?.startup_inspiration}
          transcription={data?.startup_inspiration_transcription}
        />
        <VideoAnswer
          value={data?.startup_differentiation}
          text={
            `Upload a 1 minute video explaining  why you need a scholarship and how much financial aid you require.`
          }
          index={"3"}
          link={!!data?.startup_differentiation}
          transcription={data?.startup_differentiation_transcription}
        />
      </div>

      <div style={headingStyle}>Payment</div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <LabelValuePair label="Application Fee" value={`1000 INR`} />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <LabelValuePair
            label="Status"
            value={data?.user_order ? "Paid" : "Pending"}
            paymentStatus={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicInformation;
