import { Font, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Outfit",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4G-EiAou6Y.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4QK1C4G-EiAou6Y.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4e6yC4G-EiAou6Y.ttf",
      fontWeight: 600,
    },
    {
      src: "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4deyC4G-EiAou6Y.ttf",
      fontWeight: 700,
    },
  ],
});

export const styles = StyleSheet.create({
  pdfViewer: {
    width: "100vw",
    height: "100vw",
  },
  image1: {
    height: "18px",
    width: "150px",
  },
  image2: {
    height: "22px",
    width: "42px",
  },
  headingText: {
    fontFamily: "Outfit",
    textAlign: "center",
    fontSize: "20px",
    color: "#272727",
    fontWeight: "semibold",
  },
  TopNavbar: {
    backgroundColor: "#F7F9FC",
    padding: "12px 20px",
  },
  navbarIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainContainer: {
    margin: "20px 30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  profilePhoto: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
  },
  userName: {
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: "semibold",
    textAlign: "left",
  },
  userEmail: {
    fontFamily: "Outfit",
    fontSize: "10px",
    textAlign: "left",
    marginTop:"10px"
  },
  flexContainer1: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexContainer2: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexContainer3: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "flex-start",
    marginBottom: "10px",
  },
  textStyle1: {
    fontFamily: "Outfit",
    fontSize: "10px",
    fontWeight: "semibold",
    textAlign: "left",
    marginBottom: "10px",
    color: "#6F747E",
  },
  textStyle2: {
    fontFamily: "Outfit",
    fontSize: "10px",
    fontWeight: "semibold",
    textAlign: "left",
    marginBottom: "10px",
    color: "#272727",
    margin: "auto",
  },
  score: {
    fontFamily: "Outfit",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#043A87",
  },
  detailsBorder: {
    borderBottom: "0.6px solid #6A6A6A",
    margin: "0px 30px",
  },
  questionContainer: {
    margin: "10px 30px",
  },
  indexStyle: {
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: "semibold",
    textAlign: "center",
    color: "#FFFFFF",
    backgroundColor: "#6A6A6A",
    padding: "2px 7px",
    borderRadius: "50%",
  },
  ansIconStyle: {
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: "semibold",
    color: "#FFFFFF",
    backgroundColor: "#F1554C",
    padding: "2px 5px",
    borderRadius: "50%",
    textAlign: "center",
  },
  quesStyle: {
    fontFamily: "Outfit",
    fontSize: "11px",
    fontWeight: "semibold",
    color: "#272727",
    flex: 1,
  },
  ansStyle: {
    fontFamily: "Outfit",
    fontSize: "10px",
    color: "#272727",
    flex: 1,
  },
  ansStyle1: {
    fontFamily: "Outfit",
    fontSize: "10px",
    color: "#272727",
    // flex: 1,
  },
  scoreLogicContainer:{
    backgroundColor:"#F6F6F6",
    padding:"10px",
    borderRadius:"10px",
    marginBottom:"20px"
  },
  scoreTextStyle: {
    fontFamily: "Outfit",
    fontSize: "11px",
    fontWeight: "semibold",
    color: "#272727",
    marginBottom:"5px"
  },
  container:{
    marginTop:"20px"
  }
});
