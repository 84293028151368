import { Box, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Pagination from "./Pagination";
import dayjs from "dayjs";
import { HiOutlineThumbDown } from "react-icons/hi";
import { HiOutlineThumbUp } from "react-icons/hi";
import { HiThumbDown } from "react-icons/hi";
import { HiThumbUp } from "react-icons/hi";
import { toast } from "react-toastify";
import { feedbackById } from "../services/user";
import { useQuery } from "../components/AllApplicationTable";

const textStyle = {
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "17px",
  letterSpacing: "0.02em",
  textAlign: "left",
  color: "#272727",
  padding: "16px 15px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const iconParent = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
};

const countStyle = {
  color: "#FFFFFF",
  position: "absolute",
  right: "-5px",
  bottom: "0px",
  backgroundColor: "black",
  padding: "0px 4px",
  borderRadius: "50%",
  fontSize: "9px",
};

const interviewStatusStyle = {
  padding: "4px 6px 4px 8px",
  borderRadius: "40px",
  textAlign: "center",
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "17px",
  letterSpacing: "0.02em",
};

const ApplicationTable = ({
  handleApplicant,
  totalPages,
  data,
  handlePageChange,
  currentPage,
  setFeedbackCall,
  applicationList,
  setApplicationList,
  handleSortData,
  handleSortScore,
}) => {
  const query = useQuery();
  const approval = query.get("approval") ?? "";

  const handleFeedback = async (id, approved, disapproved) => {
    console.log(approved, disapproved);
    const adminId = localStorage.getItem("adminId");
    const newData = [...applicationList];
    const index = newData.findIndex((item) => item.id === id);
    const data = {
      admin_user_id: adminId,
      approval: approved,
      disapproval: disapproved,
    };
    const resp = await feedbackById(id, data);

    newData[index] = {
      ...newData[index],
      is_approved: approved,
      is_disapproved: disapproved,
    };
    setApplicationList(newData);
    setFeedbackCall((pre) => !pre);
    toast.success("feedback provided successfully!");
  };

  const feedbackUi = (
    id,
    approved,
    disapproved,
    likeCount,
    DislikeCount,
    likeNames,
    DislikeNames,
    data
  ) => {
    const adminType = localStorage.getItem("adminType") ?? "";
    likeNames = likeNames.join(", ");
    DislikeNames = DislikeNames.join(", ");
    if (adminType === "Superuser") {
      return (
        <div style={{ display: "flex", gap: "20px" }}>
          <span style={iconParent}>
            <Tooltip title={likeNames} placement="bottom-start" arrow>
              <span>
                <HiThumbUp
                  size={25}
                  color="#03C972"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Tooltip>
            <span style={countStyle}>{likeCount}</span>
          </span>
          <span style={iconParent}>
            <Tooltip title={DislikeNames} placement="bottom-start" arrow>
              <span>
                <HiThumbDown
                  size={25}
                  color="#FF483E"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Tooltip>
            <span style={countStyle}>{DislikeCount}</span>
          </span>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", gap: "20px" }}>
          {approved ? (
            <span style={iconParent}>
              <Tooltip title={likeNames} placement="bottom-start" arrow>
                <span>
                  <HiThumbUp
                    onClick={() =>
                      handleFeedback(
                        id,
                        approved === null
                          ? true
                          : approved === true
                            ? false
                            : true,
                        false
                      )
                    }
                    size={25}
                    color="#03C972"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Tooltip>
              <span style={countStyle}>{likeCount}</span>
            </span>
          ) : (
            <span style={iconParent}>
              <Tooltip title={likeNames} placement="bottom-start" arrow>
                <span>
                  <HiOutlineThumbUp
                    color="#DCDDDD"
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleFeedback(
                        id,
                        approved === null
                          ? true
                          : approved === true
                            ? false
                            : true,
                        false
                      )
                    }
                  />
                </span>
              </Tooltip>
              <span style={countStyle}>{likeCount}</span>
            </span>
          )}
          {disapproved ? (
            <span style={iconParent}>
              <Tooltip title={DislikeNames} placement="bottom-start" arrow>
                <span>
                  <HiThumbDown
                    onClick={() =>
                      handleFeedback(
                        id,
                        false,
                        disapproved === null
                          ? true
                          : disapproved === true
                            ? false
                            : true
                      )
                    }
                    size={25}
                    color="#FF483E"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </Tooltip>
              <span style={countStyle}>{DislikeCount}</span>
            </span>
          ) : (
            <span style={iconParent}>
              <Tooltip title={DislikeNames} placement="bottom-start" arrow>
                <span>
                  <HiOutlineThumbDown
                    color="#DCDDDD"
                    size={25}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleFeedback(
                        id,
                        false,
                        disapproved === null
                          ? true
                          : disapproved === true
                            ? false
                            : true
                      )
                    }
                  />
                </span>
              </Tooltip>
              <span style={countStyle}>{DislikeCount}</span>
            </span>
          )}
        </div>
      );
    }
  };

  return (
    <Box
      sx={{
        width: "82vw",
        maxHeight: "65vh",
        borderLeft: "1px solid #929EAE",
        borderBottom: "1px solid #929EAE",
        borderRight: "1px solid #929EAE",
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxHeight: "55vh",
          overflow: "auto",
          borderBottom: "1px solid #929EAE",
        }}
      >
        <table
          style={{
            width: "100%",
            textAlign: "left",
            borderCollapse: "collapse",
          }}
        >
          <thead
            style={{
              backgroundColor: "#26292D",
              color: "#FFFFFF",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <tr>
              <th
                style={{
                  ...textStyle,

                  color: "#FFFFFF",
                  textAlign: "center",
                }}
                width="7%"
              >
                Sr No
              </th>
              <th
                style={{
                  ...textStyle,

                  color: "#FFFFFF",
                  textAlign: "center",
                }}
                width="7%"
              >
                Unique Id
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#FFFFFF" }}
                width="25%"
              >
                Applicant Name
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#FFFFFF" }}
                width="25%"
              >
                Email
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#FFFFFF" }}
                width="10%"
              >
                Application Date
              </th>
              <th
                scope="col"
                style={{
                  ...textStyle,
                  color: "#FFFFFF",
                  cursor: approval && approval === "4" ? "pointer" : "",
                }}
                width="10%"
                onClick={
                  approval && approval === "4"
                    ? handleSortData
                    : () => {
                      return null;
                    }
                }
              >
                {approval && approval === "4" ? (
                  <Tooltip
                    title={"Sort Interview Date"}
                    placement="bottom-start"
                    arrow
                  >
                    Interview Date
                  </Tooltip>
                ) : (
                  "Interview Date"
                )}
              </th>
              <th
                scope="col"
                style={{
                  ...textStyle,
                  color: "#FFFFFF",
                  textAlign: "center",
                  cursor: approval && approval === "4" ? "pointer" : "",
                }}
                width="7%"
                onClick={
                  approval && approval === "4"
                    ? handleSortScore
                    : () => {
                      return null;
                    }
                }
              >
                {approval && approval === "4" ? (
                  <Tooltip title={"Sort Score"} placement="bottom-start" arrow>
                    Score
                  </Tooltip>
                ) : (
                  "Score"
                )}
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#FFFFFF", textAlign: "center" }}
                width="10%"
              >
                CV Links
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#FFFFFF", textAlign: "center" }}
                width="15%"
              >
                Application Status
              </th>
              <th
                scope="col"
                style={{ ...textStyle, color: "#FFFFFF", textAlign: "center" }}
                width="15%"
              >
                <HiThumbUp size={22} color="#FFFFFF" />{" "}
                <HiThumbDown size={22} color="#FFFFFF" />
              </th>


            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((application, index) => (
                <tr
                  key={application?.id}
                  style={{
                    borderBottom: "1px solid #929EAE",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <td
                    style={{
                      ...textStyle,

                      padding: "7px 15px",
                      color: "#272728",
                      textAlign: "center",
                    }}
                  >
                    {currentPage !== 1
                      ? index + 1 + (currentPage - 1) * 50
                      : index + 1}
                  </td>
                  <td style={{ ...textStyle, textAlign: "center" }}>
                    {application.id ?? "--"}
                  </td>
                  <td
                    style={{
                      ...textStyle,

                      color: "#043A87",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleApplicant(application?.id, application)
                    }
                  >
                    {application?.firstname ?? "--"} {application?.lastname}
                  </td>
                  <td style={textStyle}>{application?.email ?? "--"}</td>
                  <td style={textStyle}>
                    {application.created
                      ? dayjs(application.created).format("DD/MMM/YYYY")
                      : "--"}
                  </td>
                  <td style={textStyle}>
                    {application.interview_time
                      ? dayjs(application.interview_time).format("DD/MMM/YYYY")
                      : "--"}
                  </td>
                  <td style={{ ...textStyle, textAlign: "center" }}>
                    {application.total_score ?? "--"}
                  </td>
                  <td style={{ ...textStyle, textAlign: "center" }}>
                    {application.cv_url ? (
                      <a
                        href={application.cv_url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        CV Link
                      </a>
                    ) : (
                      "--"
                    )}
                  </td>
                  <td
                    style={{
                      ...textStyle,

                      padding: "7px 15px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        ...interviewStatusStyle,
                        color:
                          application?.navigator_state === 0
                            ? "#6B6B6B"
                            : application?.navigator_state === 1
                              ? "#D86365"
                              : application?.navigator_state === 2
                                ? "#FFFFFF"
                                : application?.navigator_state === 3
                                  ? "#D2A83A"
                                  : application?.navigator_state === 5 ||
                                    application?.navigator_state === 4
                                    ? "#66C9F2"
                                    : application?.navigator_state === 6
                                      ? "#03C93B"
                                      : "",
                        backgroundColor:
                          application?.navigator_state === 0
                            ? "#DDDCDB"
                            : application?.navigator_state === 1
                              ? "#EFD2D2"
                              : application?.navigator_state === 2
                                ? "#C0D0E4"
                                : application?.navigator_state === 3
                                  ? "#F9F4E6"
                                  : application?.navigator_state === 5 ||
                                    application?.navigator_state === 4
                                    ? "#E6F6FD"
                                    : application?.navigator_state === 6
                                      ? "#E6FAEB"
                                      : "",
                      }}
                    >
                      {application?.navigator_state === 0 && "Data not Filled "}
                      {application?.navigator_state === 1 &&
                        "Personal Details Filled"}
                      {application?.navigator_state === 2 &&
                        "Education Details Filled"}
                      {application?.navigator_state === 3 && "Video Uploaded"}
                      {application?.navigator_state === 5 &&
                        "Payment Done and Interview link Sent"}
                      {application?.navigator_state === 6 && "Interview Done"}
                    </div>
                  </td>
                  <td style={{ ...textStyle, textAlign: "center" }}>
                    {application?.navigator_state === 6 &&
                      feedbackUi(
                        application.id,
                        application?.approved,
                        application?.disapproved,
                        application?.approved_invigilators?.length ?? 0,
                        application?.disapproved_invigilators?.length ?? 0,
                        application?.approved_invigilators,
                        application?.disapproved_invigilators,
                        application
                      )}

                    {application?.navigator_state <= 5 && (
                      <div style={{ display: "flex", gap: "20px" }}>
                        {/* <HiOutlineThumbUp color="#DCDDDD" size={25}/> */}
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <HiThumbUp size={25} color="#EFEFEF" />
                          {/* <span
                            style={{
                              fontFamily: "Segoe UI",
                              fontSize: "10px",
                              fontWeight: 400,
                              lineHeight: "10px",
                              letterSpacing: "0.02em",
                              visibility: "hidden",
                            }}
                          >
                            Approved
                          </span> */}
                        </span>
                        {/* <HiOutlineThumbDown color="#DCDDDD" size={25}/> */}
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <HiThumbDown size={25} color="#EFEFEF" />
                          {/* <span
                            style={{
                              fontFamily: "Segoe UI",
                              fontSize: "10px",
                              fontWeight: 400,
                              lineHeight: "10px",
                              letterSpacing: "0.02em",
                              visibility: "hidden",
                            }}
                          >
                            Disapproved
                          </span> */}
                        </span>
                      </div>
                    )}
                  </td>

                </tr>

              ))
            ) : (

              <tr
                style={{
                  borderBottom: "1px solid #929EAE",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <td
                  align="center"
                  colSpan={7}
                  style={{ padding: "12px 24px", color: "#272728" }}
                >
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Pagination
        currentDataLength={data?.length}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePage={(value) => handlePageChange(value)}
      />
    </Box>
  );
};

export default ApplicationTable;
