import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import SearchField from "../uiComponents/SearchField";
import FilterByStatus from "../uiComponents/FilterByStatus";
import { Box, CircularProgress } from "@mui/material";
import ApplicationTable from "../uiComponents/ApplicationTable";
import CommonDateFilterPanel from "./filter/CommonDateFilterPanel";
import { filterSelectOptions } from "../pages/Overview";
import { getUserDashboardDetails } from "../services/user";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

export const statusOptions = [
  {
    label: "Data not Filled",
    value: "0",
  },
  {
    label: "Personal Details Filled",
    value: "1",
  },
  {
    label: "Education Details Filled",
    value: "2",
  },
  {
    label: "Video Uploaded",
    value: "3",
  },
  {
    label: "Payment Done and Interview Link Sent",
    value: "5",
  },
  {
    label: "Interview Done",
    value: "6",
  },
  {
    label: "Wrong Applications",
    value: "7",
  },
];

export const approvalOptions = [
  {
    label: "Multiple Approval",
    value: "multiple_approval",
  },
  {
    label: "Single Approval",
    value: "single_approval",
  },
  {
    label: "Not Evaluated",
    value: "no_approval",
  },
  {
    label: "Disapproved",
    value: "disapproved",
  },
];

export const scoreOptions = [
  {
    label: "Greater Than 40",
    value: "greater_than_40",
  },
  {
    label: "Less Than 40",
    value: "less_than_40",
  },
];

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AllApplicationTable = ({
  handleApplicant,
  setTotalUser,
  setError,
  applicationList,
  setApplicationList,
}) => {
  const adminType = localStorage.getItem("adminType");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unique = queryParams.get("unique");
  const filterStatusOptions =
    adminType === "Invigilators"
      ? statusOptions?.filter((option) => option.label === "Interview Done")
      : statusOptions;

  const initialDateFilter = () => {
    return {
      select: "ALL_TIME",
      from_time: null,
      to_time: null,
      name: "",
      filterBy: adminType !== "Invigilators" ? "" : "6",
      page: "1",
      filterByApproval: "",
      filterByScore: "",
    };
  };

  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [feedbackCall, setFeedbackCall] = useState(false);
  const query = useQuery();
  const filter = query.get("filter");
  const approval = query.get("approval") ?? "";
  const wrongApplication = query.get("wrong-application") ?? "";

  const [scoreSort, setScoreSort] = useState("lth");
  const [dateSort, setDataSort] = useState("lth");

  const handleSortScore = () => {
    const sortedData = [...data?.data]?.sort((a, b) => {
      if (scoreSort === "lth") {
        setScoreSort("htl");
        return a.total_score - b.total_score;
      } else {
        setScoreSort("lth");
        return b.total_score - a.total_score;
      }
    });
    setData((pre) => {
      return { ...pre, data: sortedData };
    });
  };

  const handleSortData = () => {
    const sortedData = [...data?.data].sort((a, b) => {
      const dateA = new Date(a["interview_time"]);
      const dateB = new Date(b["interview_time"]);

      if (dateSort === "lth") {
        setDataSort("htl");
        return dateA - dateB;
      } else {
        setDataSort("lth");
        return dateB - dateA;
      }
    });
    setData((pre) => {
      return { ...pre, data: sortedData };
    });
  };

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      let endDate = dayjs();
      let startDate = dayjs();
      if (value === "THIS_WEEK") {
        startDate = endDate.subtract(7, "day");
      }
      if (value === "THIS_MONTH") {
        startDate = endDate.subtract(1, "month");
      }
      if (value === "THIS_YEAR") {
        startDate = endDate.subtract(1, "year");
      }
      if (value === "ALL_TIME") {
        startDate = null;
        endDate = null;
      }
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: startDate,
        to_time: endDate,
      }));
      return;
    }
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserDashBoardDetails = async () => {
    const startDate = dateFilter?.from_time;
    const endDate = dateFilter?.to_time;
    const search = dateFilter?.name ?? "";
    let wrongApplications = "";
    let status = "";
    if (dateFilter?.filterBy === "7") {
      wrongApplications = "1";
    } else {
      status = dateFilter?.filterBy ?? "";
    }
    const page = dateFilter?.page ?? "";
    const approval = dateFilter.filterByApproval ?? "";
    const singleApproval = approval === "single_approval" ? 1 : "";
    const multiApproval = approval === "multiple_approval" ? 2 : "";
    const noApproval = approval === "no_approval" ? 1 : "";
    const disapproved = approval === "disapproved" ? 1 : "";
    const score = dateFilter.filterByScore ?? "";
    const lessThan40 = score === "less_than_40" ? 1 : "";
    const greaterThan40 = score === "greater_than_40" ? 1 : "";

    const resp = await getUserDashboardDetails(
      startDate,
      endDate,
      search,
      status,
      page,
      setLoading,
      setError,
      singleApproval,
      multiApproval,
      noApproval,
      disapproved,
      lessThan40,
      greaterThan40,
      wrongApplications,
      unique
    );
    setData(resp);
    setLoading(false);
    setTotalUser(resp?.totalusers);
  };

  // Memoize the debounced function
  const debouncedHandleUserDashBoardDetails = useMemo(
    () => debounce(handleUserDashBoardDetails, 300),
    [dateFilter]
  );

  useEffect(() => {
    debouncedHandleUserDashBoardDetails();
    // Cleanup function to cancel the debounce if the component unmounts or effect re-runs
    return () => {
      debouncedHandleUserDashBoardDetails.cancel();
    };
  }, [dateFilter, debouncedHandleUserDashBoardDetails, feedbackCall]);

  useEffect(() => {
    if (filter) {
      handleDateFilter("page", "1");
      handleDateFilter("filterBy", filter);
    } else if (approval) {
      handleDateFilter("page", "1");

      if (approval == 4) {
        handleDateFilter("filterByApproval", "no_approval");
        return;
      }
      if (approval == 3) {
        handleDateFilter("filterByApproval", "disapproved");
        return;
      }
      if (approval == 1) {
        handleDateFilter("filterByApproval", "single_approval");
      } else {
        handleDateFilter("filterByApproval", "multiple_approval");
      }
    } else if (wrongApplication) {
      handleDateFilter("page", "1");
      handleDateFilter("filterBy", "7");
    }
  }, [filter, approval, wrongApplication]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "150px" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <Box
      sx={{
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          p: "10px 8px",
          borderTop: "1px solid #929EAE",
          borderLeft: "1px solid #929EAE",
          borderRight: "1px solid #929EAE",
          width: "81vw",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <SearchField
            width={"400px"}
            value={dateFilter?.name}
            onChange={(value) => {
              handleDateFilter("page", "1");
              handleDateFilter("name", value);
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <CommonDateFilterPanel
              filterSelectOptions={filterSelectOptions}
              dateFilter={dateFilter}
              handleDateFilter={(name, value) => {
                handleDateFilter("page", "1");
                handleDateFilter(name, value);
              }}
            />
            <FilterByStatus
              width={"200px"}
              value={dateFilter?.filterBy}
              label="Filter by status"
              onChange={(value) => {
                handleDateFilter("page", "1");
                handleDateFilter("filterBy", value);
              }}
              filterOptions={filterStatusOptions}
            />
            {adminType !== "Invigilators" && (
              <FilterByStatus
                width={"200px"}
                value={dateFilter?.filterByApproval}
                label="Filter by Approvals"
                onChange={(value) => {
                  handleDateFilter("page", "1");
                  handleDateFilter("filterByApproval", value);
                }}
                filterOptions={approvalOptions}
              />
            )}
            {approval === "4" && (
              <FilterByStatus
                width={"200px"}
                value={dateFilter?.filterByScore}
                label="Filter by Score"
                onChange={(value) => {
                  handleDateFilter("page", "1");
                  handleDateFilter("filterByScore", value);
                }}
                filterOptions={scoreOptions}
              />
            )}
          </Box>
        </Box>
      </Box>
      <ApplicationTable
        handleApplicant={handleApplicant}
        data={data?.data}
        totalPages={data.totalpages}
        currentPage={dateFilter?.page}
        handlePageChange={(value) => handleDateFilter("page", value)}
        setFeedbackCall={setFeedbackCall}
        applicationList={applicationList}
        setApplicationList={setApplicationList}
        handleSortData={handleSortData}
        handleSortScore={handleSortScore}
      />
    </Box>
  );
};

export default AllApplicationTable;
