import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import React from "react";
import { IoSearchOutline } from "react-icons/io5";
const SearchField = ({ onChange, value,width }) => {
  return (
    <div>
      <FormControl fullWidth  variant="outlined">
        <OutlinedInput
          size="small"
          placeholder="Search by Applicant's First Name"
          value={value ?? ""}
          sx={{p:"7px",width:width}}
          onChange={(e) => onChange(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <IoSearchOutline color="#000000" size={24} />
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

export default SearchField;
