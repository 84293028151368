import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";
const analyticsCashTextStyle = {
  fontFamily: "Segoe UI",
  fontSize: "26px",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "0em",
  textAlign: "left",
};

const AnalyticsCard = ({
  value,
  title,
  width,
  height,
  titleColor = "#363636",
  sideBackgroundColor = "#9383D1",
  clickable = false,
  filter = "",
  approval = "",
  showThumb = "",
  wrongApplication = "",
  selected = "",
}) => {
  const navigate = useNavigate();
  // css
  const containerStyle = {
    width: width ?? "100%",
    height: height ?? "100px",
    gap: "10%",
    borderRadius: "12px",
    background: "#fff",
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center", // Vertically center the content
    cursor: clickable ? "pointer" : "",
  };

  const analyticsTitleTextStyle = {
    fontFamily: "Segoe UI",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "21.28px",
    color: titleColor,
    textTransform: "capitalize",
    textAlign: "left",
    marginTop: "10px",
  };

  const handleNavigate = () => {
    if (filter) {
      navigate(`/applicants?filter=${filter}`);
    } else if (approval) {
      navigate(`/applicants?approval=${approval}`);
    } else if (wrongApplication) {
      navigate(`/applicants?wrong-application=${wrongApplication}`);
    } else if (selected) {
      navigate(`/applicants?selected=${selected}`);
    } else {
      navigate(`/applicants`);
    }
  };

  const handleThumbIcon = (showThumb) => {
    switch (showThumb) {
      case "multiThumbUp":
        return (
          <div
            style={{ color: "#03C972", fontSize: "25px", paddingRight: "5px" }}
          >
            <HiThumbUp />
            ++
          </div>
        );
      case "singleThumbUp":
        return (
          <HiThumbUp
            style={{ color: "#03C972", fontSize: "25px", paddingRight: "5px" }}
          />
        );
      case "multiThumbDown":
        return (
          <div
            style={{ paddingRight: "5px", color: "#FF483E", fontSize: "25px" }}
          >
            <HiThumbDown />
            ++
          </div>
        );
      // case "singleThumbDown":
      //   return <div style={{paddingRight:"5px"}}> <HiThumbDown color="#FF483E" size={25} /></div>;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={containerStyle}
      onClick={() => (clickable ? handleNavigate() : () => { })}
    >
      <Box
        sx={{
          width: "6px",
          height: "96%",
          background: sideBackgroundColor,
          borderRadius: "12px 0 0  12px",
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={analyticsCashTextStyle}>{value}</span>{" "}
          {handleThumbIcon(showThumb)}
        </Typography>
        <Typography sx={analyticsTitleTextStyle}>{title}</Typography>
      </Box>
    </Box>
  );
};

export default AnalyticsCard;
