import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import AnalyticsCardUnique from "./AnalyticsCardUnique";

const AnalyticsSectionUnique = ({
    options = [],
    title = "",
    titleColor,
    sideBackgroundColor,
    analyticsCardHeight,
    analyticsCardWidth,
    analyticColumnGap,
    analyticRowGap,
    clickable = false,
}) => {
    return (
        <Stack spacing={3}>
            {title && (
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: "Segoe UI",
                        fontSize: "20px",
                        fontWeight: 600,
                        lineHeight: "26.6px",
                        letterSpacing: "0em",
                        textAlign: "left",
                    }}
                >
                    {title}
                </Typography>
            )}
            {options.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        gridColumnGap: analyticColumnGap || "3%",
                        gridRowGap: analyticRowGap || "25px",
                    }}
                >
                    {options.map((analyticsItem, index) => (
                        <AnalyticsCardUnique
                            clickable={clickable}
                            value={analyticsItem?.value}
                            key={index}
                            showThumb={analyticsItem?.showThumb ?? ""}
                            title={analyticsItem?.label}
                            filter={analyticsItem?.filter}
                            approval={analyticsItem?.approval}
                            wrongApplication={analyticsItem?.wrongApplication}
                            titleColor={titleColor}
                            width={analyticsCardWidth}
                            height={analyticsCardHeight}
                            sideBackgroundColor={sideBackgroundColor}
                        />
                    ))}
                </Box>
            )}
        </Stack>
    );
};

export default AnalyticsSectionUnique;
