import React, { useContext, useState } from "react";
import VideoUi from "./VideoUi";
import { proctoringTest } from "../services/user";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  LinearProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaCheckCircle } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import dayjs from "dayjs";
import WaitForInterview from "../uiComponents/WaitForInterview";
import { UserContext } from "../context/UserContext";
const videoContainerStyle = {
  display: "flex",
  gap: "150px",
  flexWrap: "wrap",
  alignItems: "flex-start",
  marginTop: "50px",
};

const headingStyle = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "21px",
  textAlign: "left",
  marginBottom: "10px",
};

const progressingTextStyle = {
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "17px",
  textAlign: "left",
  fontStyle: "italic",
};

const accordionHeading = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "21px",
  textAlign: "right",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};

const proctoringDataStyle={
   display: "flex", 
   justifyContent: "space-between",
   marginRight:"30px",
   fontFamily: "Segoe UI",
   fontSize: "15px",
   fontWeight: 400,
   lineHeight: "19px",
   textAlign: "left",
   marginBottom:"10px"
   }

const RecordingInformation = ({ data }) => {
  const { getProctorData, userStates } = useContext(UserContext);
  const date = dayjs(data?.date_time_interview);
  const formattedDate = date.format("D MMM YYYY"); // Format for the date
  const loading = userStates[data?.id]?.loading;

  const handleProctor = async (id, video_url) => {
    const resp = await getProctorData(id, video_url);
  };

  if (data?.detail === "Interview not started yet") {
    return <WaitForInterview text={data?.detail} />;
  }

  return (
    <>
      <div style={videoContainerStyle}>
        <div>
          <div style={headingStyle}>Video Recording</div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <VideoUi
              videoUrl={data?.video_recorder}
              heading={`interview ${formattedDate} video-recording.mp4`}
            />{" "}
            {/* {data?.video_recorder && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#272727",
                  "&:hover": { backgroundColor: "#272727" },
                  textTransform: "none",
                }}
                disabled={loading}
                onClick={() => handleProctor(data.id, data?.video_recorder)}
              >
                Proctor
              </Button>
            )} */}
          </div>
          {/* {loading && (
            <div style={{ marginTop: "10px" }}>
              <LinearProgress />
              <span style={progressingTextStyle}>
                Proctoring in progress
              </span>{" "}
            </div>
          )} */}
        </div>
        <div>
          <div style={headingStyle}>Screen Recording</div>
          <VideoUi
            videoUrl={data?.screen_recorder}
            heading={`interview ${formattedDate} screen-recording.mp4`}
          />
        </div>
      </div>
      {/* <Accordion
        sx={{
          background: "#FFFFFF",
          marginTop: "20px",
          border: "1px solid #D5DAE1",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{ ...accordionHeading, color: "#2CAE2A" }}>
            <FaCheckCircle size={20} color="#2CAE2A" /> Mark as assessment clear
          </div>
          <div style={{ ...accordionHeading, color: "#F1554C" }}>
            <IoWarning size={20} color="#F1554C" /> Mark as suspicious found in
            assessment
          </div>
          <div style={{ ...accordionHeading, color: "#272727" }}>
            Proctoring Details
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            <li>
              <div style={proctoringDataStyle}>
                The candidate is looking away during the interview?
                <span style={{fontWeight:700}}>Yes</span>
              </div>
            </li>
            <li>
              <div style={proctoringDataStyle}>
                The candidate's face is not detected during the interview?
                <span style={{fontWeight:700}}>Yes</span>
              </div>
            </li>
            <li>
              <div style={proctoringDataStyle}>
                Multiple candidates detected during the interview?
                <span style={{fontWeight:700}}>Yes</span>
              </div>
            </li>
          </ul>

          <div
            style={{
              display: "flex",
              gap: "15px",
              padding: "15px",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2CAE2A",
                "&:hover": { backgroundColor: "#2CAE2A" },
                textTransform: "none",
              }}
            >
              Mark as Clear
            </Button>{" "}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F1554C",
                "&:hover": { backgroundColor: "#F1554C" },
                textTransform: "none",
              }}
            >
              Mark as Suspicious
            </Button>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </>
  );
};

export default RecordingInformation;
