import React, { useMemo } from "react";
import { Typography } from "@mui/material";

function capitalizeEachWord(inputString) {
  return inputString.replace(/([a-z])([A-Z])/g, "$1 $2");
}

const Header = ({ title }) => {
  function removeHyphensAndUnderscores(inputString) {
    // Use a regular expression to replace hyphens and underscores with an icon
    return inputString.split(/[-_]/).map((part, index, array) => (
      <React.Fragment key={index}>
        {capitalizeEachWord(part)}
        {index < array.length - 1 && <span> </span>}
      </React.Fragment>
    ));
  }

  const CleanString = useMemo(
    () => removeHyphensAndUnderscores(title),
    [title]
  );
  return (
    <Typography
      variant="h5"
      fontWeight="bold"
      textTransform="capitalize"
      sx={{ color: "black" }}
    >
      {CleanString ?? ""}
    </Typography>
  );
};

export default Header;
