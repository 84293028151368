import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonDateFilterPanel from "../components/filter/CommonDateFilterPanel";

import AnalyticsSection from "../components/AnalyticsSection";
import { getAllUsersDetails } from "../services/user";
import dayjs from "dayjs";
//css
const containerStyle = {
  mx: "30px",
  OverviewY: "scroll",
  my: "45px",
  m: "0px 45px 0 45px",
  boxSizing: "border-box",
};

const initialDateFilter = () => {
  return {
    select: "ALL_TIME",
    from_time: null,
    to_time: null,
  };
};

//config
export const filterSelectOptions = [
  { label: "Custom", value: "CUSTOM" },
  { label: "Today", value: "TODAY" },
  { label: "Last 7 Days", value: "THIS_WEEK" },
  { label: "Last 30 Days", value: "THIS_MONTH" },
  { label: "Last 12 Month", value: "THIS_YEAR" },
  { label: "All Time", value: "ALL_TIME" },
];

// main component
const Overview = () => {
  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [scoreList, setScoreList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const adminType = localStorage.getItem("adminType");

  const handleDateFilter = (name, value) => {
    if (name === "select" && value !== "CUSTOM") {
      let endDate = dayjs();
      let startDate = dayjs();
      if (value === "THIS_WEEK") {
        startDate = endDate.subtract(7, "day");
      }
      if (value === "THIS_MONTH") {
        startDate = endDate.subtract(1, "month");
      }
      if (value === "THIS_YEAR") {
        startDate = endDate.subtract(1, "year");
      }
      if (value === "ALL_TIME") {
        startDate = null;
        endDate = null;
      }
      setDateFilter((prev) => ({
        ...prev,
        [name]: value,
        from_time: startDate,
        to_time: endDate,
      }));
      return;
    }
    setDateFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getAllUserDate = async (dateFilter) => {
    // if (dateFilter?.from_time.isValid() && dateFilter?.to_time.isValid()) {
    const resp = await getAllUsersDetails(
      dateFilter?.from_time,
      dateFilter?.to_time,
      setLoading,
      setError
    );

    console.log(
      !adminType.includes(["Invigilators", "Interviewers"]),
      adminType,
      "admin type"
    );

    const applicantsList =
      adminType !== "Invigilators" && adminType !== "Interviewers"
        ? [
          {
            label: "Total Applicants",
            value: resp?.total_registrations ?? "--",
          },
          {
            label: "Data Not Filled",
            filter: "0",
            value: resp?.onzeropage ?? "--",
          },
          {
            label: "Personal Details Filled",
            filter: "1",
            value: resp?.onfirstpage ?? "--",
          },
          {
            label: "Education Details Filled",
            filter: "2",
            value: resp?.onsecondpage ?? "--",
          },
          {
            label: "Video Submitted",
            filter: "3",
            value: resp?.onthirdpage ?? "--",
          },
          {
            label: "Payment Done",
            filter: "5",
            value: resp?.payment_done ?? "--",
          },
          {
            label: "Interview Done",
            filter: "6",
            value: resp?.interview_done ?? "--",
          },
          {
            label: "Approved by more than 2 evaluators",
            approval: "2",
            value: resp?.users_approved_multiple ?? "--",
            showThumb: "multiThumbUp",
          },
          {
            label: "Approved by single evaluator only",
            approval: "1",
            value: resp?.users_approved_single ?? "--",
            showThumb: "singleThumbUp",
          },
          {
            label: "Not approved by any evaluator",
            approval: "3",
            value: resp?.users_disapproved ?? "--",
            showThumb: "multiThumbDown",
          },
          {
            label: "Not Evaluated",
            approval: "4",
            value: resp?.users_not_invigilated ?? "--",
            showThumb: "singleThumbDown",
          },
          {
            label: "Wrong Applications",
            wrongApplication: "1",
            value: resp?.wrong_applications ?? "--",
          },
          {
            label: "Selected Candidates",
            selected: "1",
            value: resp?.selected ?? "--",
          },

        ]
        : adminType === "Interviewers"
          ? [
            {
              label: "Approved By Multiple Invigilators",
              approval: "2",
              value: resp?.users_approved_multiple ?? "--",
            },
          ]
          : [
            {
              label: "Interview Done",
              filter: "6",
              value: resp?.interview_done ?? "--",
            },
          ];

    setApplicationList(applicantsList);
    setScoreList([
      {
        label: "Mean",
        value: resp?.mean_score ?? "--",
      },
      {
        label: "Median",
        value: resp?.median_score ?? "--",
      },
      {
        label: "Mode",
        value: resp?.mode_Score ?? "--",
      },
    ]);
    setLoading(false);
    // }
  };

  useEffect(() => {
    getAllUserDate(dateFilter);
  }, [dateFilter]);

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "150px" }}>
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (error) {
    return <div style={{ textAlign: "center", marginTop: "100px" }}>Error</div>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "#f7f9fc",
        paddingBottom: "50px"
      }}
    >
      <Box sx={containerStyle}>
        <Stack py={"20px"} width={"100%"} alignItems={"flex-end"}>
          <CommonDateFilterPanel
            filterSelectOptions={filterSelectOptions}
            dateFilter={dateFilter}
            handleDateFilter={handleDateFilter}
          />
        </Stack>

        <Stack gap={"25px"}>
          <AnalyticsSection
            title={"Total Applicants"}
            sideBackgroundColor={"#2B3F79"}
            analyticsCardWidth={"203px"}
            analyticsCardHeight={"100px"}
            options={applicationList}
            clickable={true}
          />
          <AnalyticsSection
            title={"Score"}
            sideBackgroundColor={"#D1712C"}
            analyticsCardWidth={"365px"}
            analyticsCardHeight={"100px"}
            options={scoreList}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Overview;
