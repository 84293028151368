// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_container__WopsU {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 38px;
  background-color: #ffffff;
  box-shadow: 0px 4px 14px 0px #00000040;
  z-index: 5;
  position: relative;
  /* margin-bottom: 20px; */
}

.navbar_navIcon__DuTau {
  margin-top: 5px;
}

.navbar_menuContainer__pU-Al {
  padding-top: 4px;
}

.navbar_navbarHeading__txyg7 {
  color: #e08d2d;
  font-size: 20px;
  font-weight: 500;
  font-family: Roboto-medium;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
}

.navbar_chooseLanguageText__lfgwZ {
  font-family: Roboto-medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  margin-bottom: 36px;
  text-align: center;
}

.navbar_RadioBtn__chGee {
  margin-bottom: 24px;
  font-family: Roboto-regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
}

.navbar_submitBtn__YYRaB {
  padding: 8px;
  width: 211px;
  background-color: #273388;
  color: #ffffff;
  margin: auto;
  border-radius: 40px;
  cursor: pointer;
  text-align: center;
}

.navbar_submitBtn__YYRaB:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,sCAAsC;EACtC,UAAU;EACV,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 18px 38px;\n  background-color: #ffffff;\n  box-shadow: 0px 4px 14px 0px #00000040;\n  z-index: 5;\n  position: relative;\n  /* margin-bottom: 20px; */\n}\n\n.navIcon {\n  margin-top: 5px;\n}\n\n.menuContainer {\n  padding-top: 4px;\n}\n\n.navbarHeading {\n  color: #e08d2d;\n  font-size: 20px;\n  font-weight: 500;\n  font-family: Roboto-medium;\n  line-height: 24px;\n  letter-spacing: 0.01em;\n  text-align: center;\n}\n\n.chooseLanguageText {\n  font-family: Roboto-medium;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: 0px;\n  margin-bottom: 36px;\n  text-align: center;\n}\n\n.RadioBtn {\n  margin-bottom: 24px;\n  font-family: Roboto-regular;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 19px;\n  letter-spacing: 0px;\n}\n\n.submitBtn {\n  padding: 8px;\n  width: 211px;\n  background-color: #273388;\n  color: #ffffff;\n  margin: auto;\n  border-radius: 40px;\n  cursor: pointer;\n  text-align: center;\n}\n\n.submitBtn:hover {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `navbar_container__WopsU`,
	"navIcon": `navbar_navIcon__DuTau`,
	"menuContainer": `navbar_menuContainer__pU-Al`,
	"navbarHeading": `navbar_navbarHeading__txyg7`,
	"chooseLanguageText": `navbar_chooseLanguageText__lfgwZ`,
	"RadioBtn": `navbar_RadioBtn__chGee`,
	"submitBtn": `navbar_submitBtn__YYRaB`
};
export default ___CSS_LOADER_EXPORT___;
