import React from "react";
import { CgSandClock } from "react-icons/cg";
const WaitForInterview = ({ text }) => {
  return (
    <div style={{ marginTop: "150px" }}>
      <div style={{ textAlign: "center" }}>
        <CgSandClock size={70} />
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "20px",
          color: "#272727",
          fontWeight: 700,
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default WaitForInterview;
