import React from 'react'
import ISBLogo from "../../assets/isbLogo.png"
import logo from "../../assets/svg/ivi-logo-png 1.svg"
import styles from './index.module.css'
const Navbar = () => {

    return (
        <div
            className={styles.container}
        >
            <div>
                <div className={styles.menuContainer}>
                </div>
                <div className={styles.navbarHeading}>
                    <img src={logo} height={30} alt='logo' />
                </div>
            </div>
            <div>
                <img src={ISBLogo} height={30} width={66} alt='menu' className={styles.menu} />
            </div>
        </div>
    )
}

export default Navbar