import React from "react";
import { BiSolidMoviePlay } from "react-icons/bi";

const videoContainerStyle = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  fontFamily: "Segoe UI",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "19px",
  textAlign: "left",
  color: "#5D79C2",
  padding: "10px 20px",
  backgroundColor: "#F6F6F6",
  borderRadius: "4px",
};

const VideoUi = ({ videoUrl,heading }) => {
  return (
    <div style={videoContainerStyle}>
      <BiSolidMoviePlay size={32} color="#333F51" />
      {videoUrl !== "" &&
      videoUrl !== undefined &&
      videoUrl !== null &&
      videoUrl !== "null" &&
      videoUrl !== "undefined" ? (
        <a
          style={{ textDecoration: "none", color: "#5D79C2" }}
          href={videoUrl}
          rel="noreferrer"
          target="_blank"
        >
          {heading}
        </a>
      ) : (
        <div>No Recording</div>
      )}
    </div>
  );
};

export default VideoUi;
