import React from "react";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";

const nameTextStyle = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "21px",
  textAlign: "left",
  padding: "5px 0px 0px 0px",
  cursor: "pointer",
};

const FeedbackList = ({ data }) => {
  const adminName = localStorage.getItem("adminName") ?? "";
  console.log(data);
  return (
    <div style={{ width: "30%" }}>
      {data?.approved_invigilators?.map((name) => {
        if (adminName !== name) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                ...nameTextStyle,
              }}
            >
              {name}
              <div style={{ display: "flex", gap: "20px" }}>
                <HiThumbUp size={25} color="#03C972" />
                <HiOutlineThumbDown color="#DCDDDD" size={25} />
              </div>
            </div>
          );
        }
      })}
      {data?.disapproved_invigilators?.map((name) => {
        if (adminName !== name) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px",
                ...nameTextStyle,
              }}
            >
              {name}
              <div style={{ display: "flex", gap: "20px" }}>
                <HiOutlineThumbUp
                  color="#DCDDDD"
                  size={25}
                  style={{ cursor: "pointer" }}
                />
                <HiThumbDown size={25} color="#FF483E" />
              </div>
            </div>
          );
        }
      })}
      {data.disapproved_invigilators.length === 0 &&
        data?.approved_invigilators.length === 0 && (
          <div style={{ marginTop: "10px" }}>No Feedback</div>
        )}
      {data.disapproved_invigilators.length === 1 &&
        data?.approved_invigilators.length === 0 &&
        data.disapproved_invigilators.includes(adminName) && (
          <div style={{ marginTop: "10px" }}>No Feedback</div>
        )}
      {data.disapproved_invigilators.length === 0 &&
        data?.approved_invigilators.length === 1 &&
        data.approved_invigilators.includes(adminName) && (
          <div style={{ marginTop: "10px" }}>No Feedback</div>
        )}
    </div>
  );
};

export default FeedbackList;
