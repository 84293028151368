import { Button, CircularProgress } from "@mui/material";

const CircularLoaderButton = ({
  loader,
  children,
  styles,
  loaderColor = "#fff",
  ...rest
}) => {
  return (
    <Button sx={styles} {...rest} disabled={loader}>
      {loader ? <CircularProgress sx={{ color: loaderColor }} /> : children}
    </Button>
  );
};

export default CircularLoaderButton;
