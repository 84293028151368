import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const indexStyle = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "21px",
  textAlign: "left",
  display: "flex",
  gap: "10px",
  alignItems: "flex-start",
};

const headingStyle = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "21px",
  textAlign: "left",
  color: "#272727",
  marginBottom: "10px",
};

const scoreStyle = {
  fontFamily: "Segoe UI",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "31px",
  textAlign: "left",
  color: "#043A87",
};

const QuestionAccordion = ({ data, ind }) => {
  return (
    <Accordion
      sx={{
        background: "#FFFFFF",
        marginBottom: "20px",
        border: "1px solid #D5DAE1",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div style={indexStyle}>
          <div
            style={{
              backgroundColor: "#6A6A6A",
              borderRadius: "50%",
              color: "#FFFFFF",
              padding: "2px 7px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {ind + 1}
          </div>
          <div>{data?.question ?? "--"}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            gap: "15px",
            padding: "15px",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "550px" }}>
            <div style={headingStyle}>Answer:</div>
            <p>{data?.answer ?? "--"}</p>
          </div>
          <div
            style={{
              backgroundColor: "#F6F6F6",
              padding: "20px",
              width: "550px",
              borderRadius: "10px",
            }}
          >
            <div style={headingStyle}>
              Score: <span style={scoreStyle}>{data?.score}/5</span>
            </div>
            <div style={headingStyle}>Score Logic:</div>
            <p>{data?.score_reasoning ?? "--"}</p>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionAccordion;
