import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { RxCross1 } from "react-icons/rx";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  backgroundColor: "#FFFFFF",
  outline: "none",
  borderRadius: "10px",
  p: "30px 50px",
};

const TranscriptionModal = ({ open, handleClose, index, text,transcription }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontfamily: "Segoe UI",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "26px",
            textAlign: "left",
            color: "#333F51",
          }}
          variant="h6"
          component="h2"
        >
          TRANSCRIPTION{" "}
          <RxCross1
            style={{ cursor: "pointer" }}
            size={24}
            onClick={handleClose}
          />
        </Typography>
        <Typography
          sx={{
            mt: "15px",
            backgroundColor: "#E8EEFF",
            p: "6px 18px",
            borderRadius: "8px",
            fontfamily: "Segoe UI",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "21px",
            textAlign: "left",
            color: "#333F51",
            display:"flex",
            gap:"5px",
            alignItems:"flex-start"
          }}
        >
          <div>{index}.</div>
          <div>{text}</div>
        </Typography>
        <Typography
          sx={{
            mt: "15px",
            fontfamily: "Segoe UI",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "19px",
            textAlign: "justify",
            height: "300px",
            overflow: "auto",
            paddingRight:"5px"
          }}
        >
         {transcription}
        </Typography>
      </Box>
    </Modal>
  );
};

export default TranscriptionModal;
