import React, { useContext, useEffect } from "react";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";
import { feedbackById } from "../services/user";
import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";
import FeedbackPopup from "./FeedbackPopup";

const navTextStyle = {
  fontFamily: "Segoe UI",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "21px",
  textAlign: "left",
  padding: "15px 0px",
  cursor: "pointer",
};

const ApplicantNav = ({
  activeNav,
  setActiveNav,
  userData,
  index,
  applicationList,
  setApplicationList,
  isOpen,
  handleClose,
}) => {
  const { setSwitchTab, switchTab } = useContext(UserContext);

  const handleSkip = (id) => {
    let newArray = [...switchTab];
    newArray = newArray?.filter((saveId) => saveId !== id);
    setSwitchTab(newArray);
    handleClose();
  };

  const handleFeedback = async (id, approved, disapproved) => {
    const adminId = localStorage.getItem("adminId");
    const newData = [...applicationList];
    const data = {
      admin_user_id: adminId,
      approval: approved,
      disapproval: disapproved,
    };
    const resp = await feedbackById(id, data);
    setTimeout(() => {
      handleSkip(id);
    }, 2000);
    toast.success("feedback provided successfully!");
    newData[index] = {
      ...newData[index],
      is_approved: approved,
      is_disapproved: disapproved,
    };
    setApplicationList(newData);
  };

  useEffect(() => {
    const adminType = localStorage.getItem("adminType");
    if (
      !userData.is_approved &&
      !userData.is_disapproved &&
      userData?.navigator_state === 6 &&
      adminType === "Invigilators"
    ) {
      setSwitchTab((prev) => [...prev, userData.id]);
    }
  }, [userData]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      }}
    >
      <FeedbackPopup
        open={isOpen}
        handleClose={handleClose}
        handleFeedback={(approved, disapproved) =>
          handleFeedback(userData?.id, approved, disapproved)
        }
        handleSkip={() => handleSkip(userData?.id)}
      />
      <div
        style={{
          display: "flex",
          gap: "40px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <span
          style={{
            ...navTextStyle,
            color: activeNav === "basicInformation" ? "#272727" : "#6F747E",
            borderBottom:
              activeNav === "basicInformation" ? "2px solid #272727" : "",
          }}
          onClick={() => setActiveNav("basicInformation")}
        >
          Basic Information
        </span>
        <span
          style={{
            ...navTextStyle,
            color: activeNav === "interview" ? "#272727" : "#6F747E",
            borderBottom: activeNav === "interview" ? "2px solid #272727" : "",
          }}
          onClick={() => setActiveNav("interview")}
        >
          Interview
        </span>
        <span
          style={{
            ...navTextStyle,
            color: activeNav === "recording" ? "#272727" : "#6F747E",
            borderBottom: activeNav === "recording" ? "2px solid #272727" : "",
          }}
          onClick={() => setActiveNav("recording")}
        >
          Recordings
        </span>
        {userData?.navigator_state === 6 && (
          <span
            style={{
              ...navTextStyle,
              color: activeNav === "feedback" ? "#272727" : "#6F747E",
              borderBottom: activeNav === "feedback" ? "2px solid #272727" : "",
            }}
            onClick={() => setActiveNav("feedback")}
          >
            Feedback
          </span>
        )}
      </div>

      {userData?.navigator_state === 6 &&
        localStorage.getItem("adminType") === "Invigilators" && (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: "#E6FAEB",
                color: "#03C93B",
                padding: "5px 20px",
                borderRadius: "40px",
              }}
            >
              Interview Done
            </span>
            <div
              style={{
                fonFamily: "Segoe UI",
                fontSize: "16px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {userData?.is_disapproved || userData?.is_approved
                ? "Approval Submitted"
                : "Submit your approval"}
            </div>
            {userData?.is_approved ? (
              <HiThumbUp
                size={25}
                color="#03C972"
                onClick={() =>
                  handleFeedback(
                    userData?.id,
                    userData?.is_approved ? false : true,
                    false
                  )
                }
                style={{ cursor: "pointer" }}
              />
            ) : (
              <HiOutlineThumbUp
                color="#DCDDDD"
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => handleFeedback(userData?.id, true, false)}
              />
            )}

            {userData?.is_disapproved ? (
              <HiThumbDown
                size={25}
                color="#FF483E"
                onClick={() =>
                  handleFeedback(
                    userData?.id,
                    false,
                    userData?.is_disapproved ? false : true
                  )
                }
                style={{ cursor: "pointer" }}
              />
            ) : (
              <HiOutlineThumbDown
                color="#DCDDDD"
                size={25}
                style={{ cursor: "pointer" }}
                onClick={() => handleFeedback(userData?.id, false, true)}
              />
            )}
          </div>
        )}
    </div>
  );
};

export default ApplicantNav;
