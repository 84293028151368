import React, { useState } from "react";
import { FaFileExcel } from "react-icons/fa";
import ExcelFileModal from "./ExcelFileModal";
import { Button, CircularProgress, Backdrop, styled } from "@mui/material";

const BlurBackdrop = styled(Backdrop)(({ theme }) => ({
  backdropFilter: "blur(5px)",
  zIndex: 10,
  color: "#fff",
}));

const ExportToExcel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <BlurBackdrop open={loading}>
        <CircularProgress color="inherit" />
      </BlurBackdrop>
      <ExcelFileModal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        setLoading={setLoading}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#272727",
          "&:hover": { backgroundColor: "#272727" },
          textTransform: "none",
          marginBottom: "5px",
          marginLeft: "5px",
        }}
        startIcon={
          <FaFileExcel size={20} sx={{ textTransform: "capitalize" }} />
        }
        onClick={() => setIsOpen(true)}
      >
        Export to Excel
      </Button>
    </>
  );
};

export default ExportToExcel;
