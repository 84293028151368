import React from "react";
import CustomDatePicker from "../CustomDatePicker";
import { Box, MenuItem, Select, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import calenderIcon from "../../assets/svg/calenderIcon.svg";

const CommonDateFilterPanel = ({
  filterSelectOptions = [],
  handleDateFilter,
  dateFilter,
}) => {
  // css
  const containerStyle = {
    width: "530px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "5px",
    padding: "7px 6px 7px 0px",
    border: "1px solid #D9D9D9",
    boxSizing: "border-box",
    background: "#fff",
  };

  return (
    <Box sx={containerStyle}>
      <img
        src={calenderIcon}
        alt="calender-icon"
        style={{ marginLeft: "20px", height: "20px", width: "20px" }}
      />
      <Select
        displayEmpty
        sx={{
          height: "38px",
          padding: "0",
          margin: "0",
          color: "#6A6A6A",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "17.05px",
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
            {
              display: "flex",
              alignItems: "center",
              marginTop: "2px",
            },
        }}
        name="select"
        value={dateFilter?.select ?? ""}
        onChange={(e) => {
          const { name, value } = e.target;
          handleDateFilter(name, value);
        }}
      >
        <MenuItem value="" disabled>
          --Select--
        </MenuItem>
        {filterSelectOptions.length > 0 &&
          filterSelectOptions.map((res) => (
            <MenuItem key={res.label} value={res.value}>
              {res.label}
            </MenuItem>
          ))}
      </Select>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction={"row"} spacing={1}>
          <CustomDatePicker
            name={"from_time"}
            isDisabled={dateFilter?.select !== "CUSTOM"}
            maxDate={dateFilter?.to_time ? dayjs(dateFilter?.to_time) : null}
            value={dateFilter?.from_time ? dayjs(dateFilter?.from_time) : null}
            onChange={(name, value) => handleDateFilter(name, value)}
            label={"From"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "15px",
                height: "2px",
                background: "#d9d9da",
              }}
            ></Box>
          </Box>
          <CustomDatePicker
            name={"to_time"}
            isDisabled={dateFilter?.select !== "CUSTOM"}
            minDate={
              dateFilter?.from_time ? dayjs(dateFilter?.from_time) : null
            }
            value={dateFilter?.to_time ? dayjs(dateFilter?.to_time) : null}
            onChange={(name, value) => handleDateFilter(name, value)}
            label={"To"}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
};

export default CommonDateFilterPanel;
