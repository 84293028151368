import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import CustomDatePicker from "../components/CustomDatePicker";
import dayjs from "dayjs";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { getExcelDetails } from "../services/user";
import FilterByStatus from "./FilterByStatus";
import {
  approvalOptions,
  statusOptions,
} from "../components/AllApplicationTable";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  backgroundColor: "#FFFFFF",
  outline: "none",
  borderRadius: "10px",
  p: "30px 50px",
};

const ExcelFileModal = ({ open, handleClose, setLoading }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unique = queryParams.get("unique");
  const today = dayjs();
  const beforeMonth = today.subtract(1, "month");
  const [toDate, setToDate] = useState(today);
  const [fromDate, setFromDate] = useState(beforeMonth);
  const [filter, setFilter] = useState("");
  const [approvalFilter, setApprovalFilter] = useState("");
  const adminType = localStorage.getItem("adminType");

  const filterStatusOptions =
    adminType === "Invigilators"
      ? statusOptions?.filter((option) => option.label === "Interview Done")
      : statusOptions;
  // Truncate function to ensure text length does not exceed Excel's limit
  const truncateText = (text, maxLength = 32767) => {
    return text.length > maxLength ? text.substring(0, maxLength) : text;
  };

  // const preprocessData = (data) => {
  //   return data.map((item) => {
  //     const newItem = {};
  //     for (const key in item) {
  //       if (typeof item[key] === "string") {
  //         newItem[key] = truncateText(item[key]);
  //       } else {
  //         newItem[key] = item[key];
  //       }
  //     }
  //     return newItem;
  //   });
  // };
  const preprocessData = (data) => {
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        if (typeof item[key] === "string") {
          newItem[key] = truncateText(item[key]);
        } else if (typeof item[key] === "object" && item[key] !== null) {
          // Check if the value is an object and not null
          newItem[key] = JSON.stringify(item[key]); // Convert the object to a string
        } else {
          newItem[key] = item[key];
        }
      }
      return newItem;
    });
  };

  const handleExcel = async () => {
    if (!toDate && !fromDate) {
      toast.error("please select date first");
      return;
    }
    const singleApproval = approvalFilter === "single_approval" ? 1 : "";
    console.log(approvalFilter + "approvalFilter ");
    const multiApproval = approvalFilter === "multiple_approval" ? 2 : "";
    const disapproved = approvalFilter === "disapproved" ? 1 : "";
    const noApproval = approvalFilter === "no_approval" ? 1 : "";

    // export const approvalOptions = [
    //   {
    //     label: "Multiple Approval",
    //     value: "multiple_approval",
    //   },
    //   {
    //     label: "Single Approval",
    //     value: "single_approval",
    //   },
    //   {
    //     label: "Not Evaluated",
    //     value: "no_approval",
    //   },
    //   {
    //     label: "Not Approved",
    //     value: "disapproved",
    //   },
    // ];

    try {
      setLoading(true);
      const data = await getExcelDetails(
        fromDate,
        toDate,
        filter,
        setLoading,
        singleApproval,
        multiApproval,
        disapproved,
        noApproval,
        unique,

      );
      if (data.data && data.data.length === 0) {
        toast.error("No data in selected date range!");
        setLoading(false);
        return;
      }

      const processedData = preprocessData(data.data);

      console.log("ecel", data);
      // Convert JSON data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(processedData);
      console.log("data.xlsx2", worksheet);
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      console.log("data.xlsx1");
      // Write the workbook to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a blob from the binary string
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Save the blob as an Excel file
      console.log(blob, "data.xlsx");
      saveAs(blob, "applicants-list.xlsx");
      handleClose();
      setLoading(false);
    } catch (error) {
      console.error("Error downloading the file", error);
      setLoading(false);
    }
  };

  // const handleExcel = async () => {
  //   if (!toDate || !fromDate) {
  //     toast.error("Please select a date range");
  //     return;
  //   }

  //   const approvalFilterValues = {
  //     single_approval: 1,
  //     multiple_approval: 2,
  //     disapproved: 1,
  //     no_approval: 1,
  //   };

  //   try {
  //     startDate,
  //       endDate,
  //       filter,
  //       setLoading,
  //       singleApproval = "",
  //       multiApproval = "",
  //       disapproved = "",
  //       noApproval = "",
  //       unique = ""
  //     setLoading(true);
  //     const data = await getExcelDetails(
  //       fromDate.toISOString(),
  //       toDate.toISOString(),
  //       filter,
  //       setLoading,
  //       approvalFilterValues[approvalFilter] || "",
  //       unique
  //     );

  //     if (!data.data || data.data.length === 0) {
  //       toast.error("No data in the selected date range");
  //       setLoading(false);
  //       return;
  //     }

  //     const processedData = preprocessData(data.data);
  //     const worksheet = XLSX.utils.json_to_sheet(processedData);
  //     const workbook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //     const excelBuffer = XLSX.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });
  //     const blob = new Blob([excelBuffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(blob, "applicants-list.xlsx");
  //     handleClose();
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error downloading the file", error);
  //     setLoading(false);
  //   }
  // };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontfamily: "Segoe UI",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "26px",
            textAlign: "left",
            color: "#333F51",
            marginBottom: "20px",
          }}
          variant="h6"
          component="h2"
        >
          EXPORT AS EXCEL
          <RxCross1
            style={{ cursor: "pointer" }}
            size={24}
            onClick={handleClose}
          />
        </Typography>
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            textAlign: "left",
            color: "#212121",
            marginBottom: "5px",
          }}
        >
          Select a date range and status
        </Typography>
        <Typography
          sx={{
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "left",
            color: "#7D7D7D",
            marginBottom: "15px",
          }}
        >
          The exported Excel sheet of all applicant details will reflect the
          selected date range and status.
        </Typography>
        <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
          <Box sx={{ width: "100%", overflow: "none" }}>
            <Typography
              sx={{
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "#7D7D7D",
              }}
            >
              Filter by Status
            </Typography>
            <FilterByStatus
              width={"700px"}
              label=""
              value={filter}
              onChange={(value) => setFilter(value)}
              filterOptions={filterStatusOptions}
            />
          </Box>
        </Box>
        {adminType !== "Invigilators" && (
          <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
            <Box sx={{ width: "100%", overflow: "none" }}>
              <Typography
                sx={{
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                  color: "#7D7D7D",
                }}
              >
                Filter by Approvals
              </Typography>
              <FilterByStatus
                width={"700px"}
                label=""
                value={approvalFilter}
                onChange={(value) => setApprovalFilter(value)}
                filterOptions={approvalOptions}
              />
            </Box>
          </Box>
        )}
        <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
          <Box sx={{ width: "100%", overflow: "none" }}>
            <Typography
              sx={{
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "#7D7D7D",
              }}
            >
              From
            </Typography>
            <CustomDatePicker
              style={{ width: "100%" }}
              name={"from_time"}
              maxDate={toDate ? dayjs(toDate) : null}
              value={fromDate ? dayjs(fromDate) : null}
              onChange={(name, value) => setFromDate(value)}
              label={""}
            />
          </Box>
          <Box sx={{ width: "100%", overflow: "none" }}>
            <Typography
              sx={{
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: "#7D7D7D",
              }}
            >
              To
            </Typography>
            <CustomDatePicker
              style={{ width: "100%" }}
              name={"to_time"}
              minDate={toDate ? dayjs(toDate) : null}
              value={toDate ? dayjs(toDate) : null}
              onChange={(name, value) => setToDate(value)}
              label={""}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#272727",
            "&:hover": { backgroundColor: "#272727" },
            textTransform: "none",
          }}
          onClick={handleExcel}
        >
          Export now
        </Button>
      </Box>
    </Modal>
  );
};

export default ExcelFileModal;
