import React from "react";

const labelStyle = {
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "17px",
  textAlign: "left",
  marginBottom: "8px",
  color: "#7D7D7D",
};

const valueStyle = {
  fontFamily: "Segoe UI",
  fontSize: "15px",
  fontWeight: 400,
  lineHeight: "19px",
  textAlign: "left",
  color: "#272727",
};

const paymentStyle = {
  fontFamily: "Segoe UI",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "16px",
  textAlign: "left",
  padding: "4px 7px",
  borderRadius: "40px",
};

const LabelValuePair = ({
  label,
  value,
  link = false,
  paymentStatus = false,
}) => {
  console.log(label, value, link);
  return (
    <div
      style={{
        minWidth: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "wrap",
      }}
    >
      <div style={labelStyle}>{label}</div>
      {link && (
        <a
          style={{
            ...valueStyle,
            textDecoration: "none",
            color: "#5D79C2",
          }}
          href={value}
          rel="noreferrer"
          target="_blank"
        >
          {value}
        </a>
      )}
      {!link && !paymentStatus && (
        <div style={valueStyle}>
          {value !== "" &&
          value !== null &&
          value !== undefined &&
          value !== "null" &&
          value !== "undefined"
            ? value
            : "--"}
        </div>
      )}
      {paymentStatus && (
        <span
          style={{
            ...paymentStyle,
            color: value === "Paid" ? "#03C93B" : "#FFA500",
            backgroundColor: value === "Paid" ? "#E6FAEB" : "#FFF8DC",
          }}
        >
          {value}
        </span>
      )}
    </div>
  );
};

export default LabelValuePair;
